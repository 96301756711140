import React from 'react';

const Footer = () => {
  return (
    <footer>
      <p>
        Je suis enthousiaste à l'idée de mettre mes compétences et mon expérience au service de votre entreprise.
      </p>
      <p>
        Cordialement,
        <br />
        Yannis Zouitni
      </p>
    </footer>
  );
};

export default Footer;
