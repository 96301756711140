import React from 'react';

const Header = () => {
  return (
    <header>
      <h1>Yannis Zouitni</h1>
      <h3>Développeur Full Stack et Administrateur Système et Réseau</h3>
    </header>
  );
};

export default Header;
